import { useEffect, useState } from "react"

// pop up api
import Swal from 'sweetalert2';

// components
import AppPageTemplate from "../../Components/AppPageTemplate/AppPageTemplate";
import Input from "../../Components/Input/Input";

// db connection
import SteinStore from "stein-js-client";



export default function({stepCounter, setAbleNextStep, setStepCounter, asesorData, setAsesorData}) {
  // form fields
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [keyword, setKeyword] = useState("")

  const hgflhjkñasdfhkñlj = "somos valoret"


  // DB auth connection
  const AsesoresDB = new SteinStore(
    "https://api.steinhq.com/v1/storages/661deda64a642363121cafb2"
  );
  





  // Check every field is filled to able sending
  useEffect(() => {
    if(username && password && keyword) {
      setAbleNextStep(true)
    } else {
      setAbleNextStep(false)
    }
  }, [username, password, keyword])





  useEffect(() => {
    if(stepCounter == 1) {
      setStepCounter(0) // Regresamos al estado 0 para hacer posible reenviar el formulario de login si hubo un error
  



      const loadingSwal = Swal.fire({ // Pop up de cargando
        title: 'Cargando...',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
            




  
      AsesoresDB // Consultamos DB donde tenemos todos los asesores
        .read("Gerentes-CRM-Valoret", {authentication: {
          username: "crm-valoret", password: "crm-valoret587943687953470985342879070953428687253487092538709523496780267"
        }})
        .then(data => {
          // Revisamos 1 por 1 a ver si coincide el usuario y contraseña con los que tenemos en la BD
          let asesoresDB = data;
          let matchIdx = null
          asesoresDB.forEach((asesor, idx) => {
            if(username == asesor?.usuario_crm && password == asesor?.clave_crm && hgflhjkñasdfhkñlj == keyword.toLowerCase()) {
              matchIdx = idx;
            }
          })
  







          // solo SI encontramos una coincidencia, cerramos el pop up de cargando y obtenemos los datos del asesor en nuestros estados 
          if(matchIdx !== null) {
            loadingSwal.close()
            
            // En este caso no necesito los datos del asesor, solamente dejaré que avance a registrar el nuevo asesor
            // setBrokerData({
            //   brokerID: asesoresDB[matchIdx].ID,
            //   brokerName: asesoresDB[matchIdx].Nombre,
            // })

            setStepCounter(2)
  
  



            



          } else {
            //SUCCESSSS LOG NOT AUTHORIZED
            setStepCounter(0)
            loadingSwal.close()
              Swal.fire({
                title: 'No autorizado',
                icon: 'error',
                showConfirmButton: false,
                showCloseButton: false,
                iconColor: "#ff4545",
                timer: 2500
              }).then(() => {
                setStepCounter(0)
              })
          }
        })
  







        .catch(e => {
          setStepCounter(0)
          
          //SWAAAAAL DE ERROOOOOOOOOOR
          loadingSwal.close()
          Swal.fire({
            title: 'Ocurrió un error',
            icon: 'error',
            showConfirmButton: false,
            showCloseButton: false,
            iconColor: "#ff4545",
            timer: 2500
          }).then(() => {
            setStepCounter(0)
          })
        });
    }
  }, [stepCounter])

  



  return(
    <AppPageTemplate title={"¡Bienvenida Afiliada!"}>
      <div >
        <h3 className="h3">Nombre de usuario</h3>
        <div style={{height: "10px"}} />
        
        <Input
          placeholder={""}
          value={username}
          onChange={e=>setUsername(e.target.value)}
        />

        <div style={{height: "30px"}} />





        <h3 className="h3">Clave del usuario</h3>
        <div style={{height: "10px"}} />
        
        <Input
          placeholder={""}
          value={password}
          onChange={e=>setPassword(e.target.value)}
        />

        <div style={{height: "30px"}} />





        <h3 className="h3">Palabra Clave</h3>
        <div style={{height: "10px"}} />
        
        <Input
          placeholder={""}
          value={keyword}
          onChange={e=>setKeyword(e.target.value)}
        />
      </div>      
    </AppPageTemplate>
  )
}




